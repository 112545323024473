import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';

const Bankaccount = ({closeBank}) => {
  const [text, setText] = useState('')
  const [submit, setSubmit] = useState()
  const [formData, setFormData] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
    routingNumber: '',
    bank_location: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }
  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    const token = localStorage.getItem('token')
    axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/input-bank-details', formData, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log(res)
      setText('Profile updated successfully')
      setSubmit(true)
    })
    .catch(e=>{
      console.log(e)
      setSubmit(false)
    })
  }
  return (
        <div className="bankAcct">
    <header>
    <h3>Set Bank info</h3>
    <img alt='cancel' src='/images/cancel.png' onClick={()=>closeBank(false)}/>
    </header>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="bankName">Bank Name</label>
        <input
          placeholder="Micheal"
          onChange={handleChange}
          value={formData.bankName}
          name="bankName"
        />
      </div>
      <div>
        <label htmlFor="accountName">Account Name</label>
        <input
          placeholder="Andrews"
          onChange={handleChange}
          value={formData.accountName}
          name="accountName"
        />
      </div>
      <div>
        <label htmlFor="accountNumber">Bank Account number</label>
        <input
          placeholder="Male"
          onChange={handleChange}
          value={formData.accountNumber}
          name="accountNumber"
        />
      </div>
      <div>
        <label htmlFor="routingNumber">Swift or BIC code</label>
        <input
          placeholder="Micheal"
          onChange={handleChange}
          value={formData.routingNumber}
          name="routingNumber"
        />
      </div>
      <div>
        <label htmlFor="bank_location">Bank Address</label>
        <input
          placeholder="Micheal"
          onChange={handleChange}
          value={formData.bank_location}
          name="bank_location"
        />
      </div>
      {submit ? text : 'Unable to update bank details'}
      {text}
      <div>
      <button type='submit' className='sub'>Save</button>
      </div>
    </form>
    </div>
  )
}

export default Bankaccount
