import React, { useEffect } from 'react'
import '../App.css'
import { Outlet } from "react-router-dom";
import SideBar from '../components/sideBar';
import TopNav from "../components/topNav";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import UpdateProfile from "../components/UpdateProfile";
import ChangePassword from "../components/changePassword";

const Home = ({isNotis, notis, notisData, userProfile}) => {
  const [mobile, setMobile] = useState(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1100px)');
    const handleMediaChange = (event) => {
      setMobile(event.matches); // Set to true if matches, false otherwise
    };
    handleMediaChange(mediaQuery); // Set initial value
    mediaQuery.addEventListener('change', handleMediaChange); // Listen for changes
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange); // Clean up listener
    };
  }, []);

//   const notis2 = {
//     title: 'Password successfully reset',
//    message: 'Your password reset was successful'
//  }
 
  const [profileDd, setProfileDd] = useState(false)
  const [updatePf, setUpdatePf] = useState(false)
  const [updatePass, setUpdatePass] = useState(false)
  function isProfileDd(isProfile){
    setProfileDd(isProfile)
  }
  function isUpdate(update){
    setUpdatePf(update)
  }
  function isPass(update){
    setUpdatePass(update)
  }

  const [openMenu, setOpenMenu] = useState(false)
  const [mopenMenu, setMopenMenu] = useState(false)

  function toggleMenu(cond){
    setOpenMenu(cond)
    console.log(cond)
  }
  function toggleMMenu(cond){
    setMopenMenu(cond)
    console.log(cond)
  }



  return (
      <>
        {
          userProfile && <div className="home">
          {
            mobile ? <div className="sideBarCon" style={{left: mopenMenu ? '0': '-100%'}}>
         <SideBar toggleMenu = {toggleMenu} openMenu={openMenu}  />
          </div> : <div className="sideBarCon" style={{display: openMenu ? 'none': 'block'}}>
         <SideBar toggleMenu = {toggleMenu} openMenu={openMenu}  />
          </div>
          }
         
          {
            profileDd && <>
            <div className="profileDrp">
            <main>
              <img alt="pfp" src="/images/user.png" />
              <NavLink to='/profile' onClick={()=>setProfileDd(false)}>Profile Settings</NavLink>
            </main>
            <main>
              <img alt="dollar" src="/images/dollar.png" />
              <NavLink to='setup-wallet' onClick={()=>setProfileDd(false)}>Setup your wallet</NavLink>
            </main>
            <main className="signout">
              <img alt="signout" src="/images/signout.png" />
              <NavLink to='/login' onClick={()=>{
                setProfileDd(false)
                localStorage.removeItem('user')
              }}>Sign out</NavLink>
            </main>
          </div>
            </>
          }
 
          <div className="bodyCon" style={{width: openMenu ? '100%' : '90%'}}>
            <TopNav notis={notis} isNotis={isNotis} notisData={notisData} userProfile = {userProfile} toggleMenu = {toggleMenu} openMenu={openMenu} toggleMMenu = {toggleMMenu} mopenMenu={mopenMenu} isProfileActive = {isProfileDd} profileCond = {profileDd}  />
           <div onClick={()=>{
            setMopenMenu(false)
            isNotis(false)
            console.log(openMenu)
           }}><Outlet/></div> 
          </div>
          </div>
        }
      </>
  );
}

export default Home
