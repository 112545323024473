import React, { useEffect, useState } from 'react'
import Balance from '../components/Balance'
import Footer from '../components/footer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Bar } from 'react-chartjs-2';//NEW CHANGE
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Wallet = ({userProfile}) => {
  const [portfolio, setPortfolio] = useState({})
  const [loading, setLoading] = useState(true);//NEW
  const [error, setError] = useState(null);//NEW 
  const navigate = useNavigate()
  const [id, setId] = useState({})
  useEffect(()=>{
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('id');
    axios.get(`https://dashboard-6h1t.onrender.com/api/v1/actions/portfolio/${userId}`, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log('all ports')
      setPortfolio(res.data)
      setLoading(false);//NEW CHANGE
    })
    .catch(e=>{
      console.log(e)
      setError('Failed to fetch portfolio data.');//NEW
      setLoading(false);//NEW
    })
  },[])

  //NEW CHANGE START

  const chartData = {
    labels: ['Total Balance', 'Total Deposit'],
    datasets: [{
      label: 'Amount in USD',
      data: [
        portfolio.totalBalance,
        portfolio.totalDeposits,
        portfolio.totalWithdrawals,
        portfolio.totalReferralBonuses,
      ],
      backgroundColor: [
        Math.abs(parseFloat(portfolio.totalDeposits - portfolio.totalBalance).toFixed(2))  ? 'rgba(75, 192, 192, 0.6)' : 'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(153, 102, 255, 0.6)',
      ],
      borderColor: 'white',
      borderWidth: 1,
    }],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        beginAtZero: false, 
      min: 1000,
      max: 50000,
      title: {
        display: true,
        text: 'Amount (USD)',
      },
        grid: {
          color: 'grey',
          lineWidth: 0.7,
          drawTicks: false,
        },
        ticks: {
          stepSize: 100,
          padding: 10,
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold',
            color: 'black',
          },
        },
      },
      // x: {
      //   title: {
      //     display: true,
      //     text: 'Metrics',
      //   },
      // },
    },
  };
//NEW CHANGE END

  return (
    <div className='wallet'>
      <section className='walletUp'>
        <div className='walletUpL'>
          <main>
            <article>
              <h4>Total profit</h4>
              <h3>${Math.abs(parseFloat(portfolio.totalDeposits - portfolio.totalBalance).toFixed(2))}</h3>
            </article>
            <article>
              <h4>Account Balance</h4>
              <h3>${portfolio.totalBalance}</h3>
            </article>
          </main>
          <button onClick={()=>{
            navigate('/withdraw')
          }}>Make withdrawal</button>
        </div>
      
        <div className='walletUpR'>
          <div style={{ position: 'relative', height: '100%', width: '98%', fontSize: '2em'}}>
            {loading && <p style={{fontSize: '12px'}}>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!loading && !error && <Bar data={chartData} options={options} />}
          </div>
        </div>

      </section>
      <section className='walletBtm'>
        <Balance name='Total Deposit' amt={portfolio.totalDeposits} sign={true} />
        <Balance name='Total Withdrawal' amt={portfolio.totalWithdrawals} sign={true} />
        <Balance name='Bonus' amt={portfolio.totalReferralBonuses} sign={true} />
        <Balance name='Target Balance' amt='1000000' sign={true} />
        <Balance name='Referral bonus' amt='50.00' sign={true} />
        <Balance name='Referral balance' amt={portfolio.totalReferralBonuses} sign={true} />
      </section>
      <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  )
}

export default Wallet