import React, {useEffect, useState} from 'react'
import '../Pages/Pages.css'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import LocationComponent from './Location';

const Footer = () => {

  return (
    <div className='foot'>
    <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}>2024 <img alt='copyright' src='/images/copy.png' width='10px' /> Hustlers University .  <small style={{color: '#f34758'}}> Region</small> <LocationComponent/></span>
    {/* <span><img alt='support' src='/images/chat.png' className='chatSupport'/></span> */}
    <span>
    <div 
                style={{border: '1px solid red'}}>
             <TawkMessengerReact
                propertyId="19f9e9aa5d8a5f7b12122ffa54246bf718ee2e42"
                widgetId="1i6mpdvb6"/>
        </div>
    </span>
  </div>
  )
}

export default Footer