import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'

const DepositHistory = () => {
  const [data, setData] = useState([])
    useEffect(()=>{
        const token = localStorage.getItem('token')
      axios.get('https://dashboard-6h1t.onrender.com/api/v1/actions/deposits', {
        withCredentials: true,
          headers : {
              Authorization: `Bearer ${token}`
          }
      })
      .then(res=>{
        console.log(res)
        setData(res.data)
      })
      .catch(e=>{
        console.log(e)
      })
    },[])
  
  return (
    <div className='depHis'>
        <h2>Deposit History</h2>
        <main>
            <ul className='header'>
                <li>Reference id</li>
                <li>Amount</li>
                <li>Worth</li>
                <li>Payment Method</li>
                <li>Status</li>
                <li>Date In</li>
            </ul>
            {
              data && <>
                {data.map(item=>{
                  return <>
            <ul className='others'>
                <li>{item.referenceId}</li>
                <li>${item.amount}</li>
                <li>{item.amount}</li>
                <li>{item.payment_method}</li>
                <li>{item.status}</li>
                <li>{item.paid_at}</li>
                </ul>
                  </>
                })}
              </>
            }
            
        </main>
    </div>
  )
}

export default DepositHistory