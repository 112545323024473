import React from "react";
import Footer from "../components/footer";
import axios from "axios";
import { useState, useEffect } from "react";


const Support = ({userProfile, addNotis}) => {
  const [formData, setFormData] = useState({
    subject: '',
    category: 'Select category',
    message: ''
});

// Handle input changes
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};

// Handle form submission
const handleSubmit = (e) => {
    e.preventDefault(); 
    const token = localStorage.getItem('token')
   axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/contact-support', formData, {
    withCredentials: true,
      headers : {
          Authorization: `Bearer ${token}`
      }
  })
   .then(res=>{
    console.log('Submitted Data:', formData);
    setFormData({
      subject: '',
        category: 'Select category',
        message: ''
    });
    const notis2 = {
      title: 'Support',
    message: `Your message has been sent successfully, we would give a response within 24hours`
  }
  addNotis(notis2)
   }).catch(e=>{
    console.log(e)
   })
};
  return (
    <div className="support">
    <h3>Support</h3>
        <main className="supportMain">
      <h3>Support Ticket</h3>
      <p>Subject</p>
      <form onSubmit={handleSubmit}>
      <div className="subject">
      <label htmlFor="subject">Enter Subject</label>
      <input
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        placeholder="Subject"
      />
        <select
          name="category"
          value={formData.category}
          onChange={handleChange}
        >
          <option value='Select category' disabled>Select category</option>
          <option value='Verification'>Verification</option>
          <option value='Change phone number'>Change phone number</option>
          <option value='Profile'>Profile</option>
          <option value='Referral'>Referral</option>
          <option value='Withdrawal'>Withdrawal</option>
          <option value='KYC data'>KYC data</option>
          <option value='Payment Complaint'>Payment Complaint</option>
          <option value='Trade Contract'>Trade Contract</option>
          <option value='Others'>Others</option>
        </select>
      </div>
      <div className="msgCon">
      <label htmlFor="message">Message</label>
    <textarea
        name="message"
      value={formData.message}
      onChange={handleChange}
    />
      </div>
        <button type="submit">Submit</button>
      </form>
    </main>
    <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  );
};

export default Support;
