import React from 'react'
import { useState } from 'react';
import './login.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'


const Login = () => {
    const [logged, setLogged] = useState(false)
    const [valCap, setValCap] = useState(null)
    const [isChecked, setIsChecked] = useState(false);
    const handleCheck = (event) => {
      setIsChecked(event.target.checked);
      console.log("Checkbox is checked:", event.target.checked);
    };
  const [eye1, setEye1]= useState(false)
  const [text, setText]= useState(false)
  const [resMessage, setResMessage] = useState('')
    const [buttonClicked, setButtonCLicked] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });
    const navigate = useNavigate()
      function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      }
    
      function handleSubmit(event) {
        try{
            event.preventDefault();
          console.log(formData);
          axios.post('https://dashboard-6h1t.onrender.com/api/v1/authentication/login', formData)
          .then(res=>{
            console.log(res)
            console.log(res.data)
            // setLogged(true)
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('user', res.data.data)
            localStorage.setItem('refLink', res.data.referralLink)
            setText(true)
            setResMessage('Resgistration successful')
            
            navigate('/')
          })
          .catch(e=>{
            console.log(e)
            setButtonCLicked(false)
            setText(false)
            setResMessage('Incorrect credentails')
          })
        }catch(e){
             console.log(e)
        }
      }
  return (
    <div className='login'>
    <section>
      <h1>Sign in to your account</h1>
      <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email">Email</label>
        <input
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="Password">Password</label>
        <span className='inputConb'>
        <input
          placeholder="password"
          name="password"
          value={formData.password}
          type= {eye1 ? 'text' : 'password'} 
          onChange={handleChange}
        />
            <img alt='eye' src='/images/eye.png' className='eye' style={{
              backgroundColor: 'black',
              width: '15px',
              padding: '7px'
            }} onClick={()=>{
            setEye1(!eye1)
        }} />
        </span>
      </div>
      {text &&  <span style={{
          fontSize: '12px',
          backgroundColor: '#fce1e0',
          color: '#9a4c4a',
          padding: '15px',
          textAlign: 'center'
        }}>
          {resMessage}
          </span>
        }
      <div style={{margin: 'auto', width: '70%'}}>
      <ReCAPTCHA
        sitekey='6LcWWz4qAAAAAMgpauX2NHUBCoFAEzRpb8-IVpRk'
        onChange={(val)=>setValCap(val)}
      />
      </div>
      <span style={{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px'
      }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheck}
        />
        <label htmlFor="terms">Remember me</label>
      </span>
      <button  onClick={()=>{
        setButtonCLicked(true)
      }}>{buttonClicked ? 'Loading...': 'Sign in'}</button>
      <a href='/reset-password'>Forgot password</a>
      <small>Don't have an account? <a href='/sign-up'>Register</a></small>
      </form>
    </section>
    </div>
  )
}

export default Login
