import React from 'react'
import { useState } from 'react';
import axios from 'axios';

const ChangePassword = ({addNotis, openUpdate, updateCond}) => {
  console.log('addNotis:', addNotis);  // This should log the function
  const [text, setText]= useState('')
  const [formData, setFormData] = useState({
    oldpassword: '',
    newpassword: '',
    cnewpassword: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value  
    }));
  }
const formData2 = {
  password: formData.newpassword
}
  function handleSubmit(event) {
    event.preventDefault();
    if(formData.newpassword === formData.cnewpassword){
      console.log(formData);
      const token = localStorage.getItem('token')
      axios.put('https://dashboard-6h1t.onrender.com/api/v1/edit-password', formData2,  {
        withCredentials: true,
          headers : {
              Authorization: `Bearer ${token}`
          }
      })
      .then(res=>{
        console.log(res)
        setText('Changed password successfully')
         const notis2 = {
          title: 'Password successfully reset',
          message: 'Your password reset was successful'
       }
        addNotis(notis2)
      })
      .catch(e=>{
        console.log(e)
        setText('Change password unsuccessful')
      })
    }else if(formData.newpassword !== formData.cnewpassword){
      console.log('password does not match')
    }
  }
  return (
    <div className="updateProfile" style={{transform: updateCond ? 'translateX(0)': 'translateX(-26%)'}}>
    <header>
    <nav>
      <h3>Update Profile settings</h3>
      <img alt="cancel" src="/images/cancel.png"  onClick={()=>openUpdate(false)} />
    </nav>
    </header>
    <form onSubmit={handleSubmit}>
      <div className="pfpCon">
        <img alt="pfp" src="/images/password.png" style={{width:'400px', border: 'none'}}/>
      </div>
      <main>
      <div>
        <label htmlFor="oldpassword">Old password</label>
        <input
          placeholder="Password"
          name="oldpassword"
          value={formData.oldpassword}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="newpassword">New password</label>
        <input
          placeholder="New Password"
          name="newpassword"
          value={formData.newpassword}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="cnewpassword">Confirm Password</label>
        <input
          placeholder="Re-Type Password"
          name="cnewpassword"
          value={formData.cnewpassword}
          onChange={handleChange}
        />
      </div>
      { text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>{text}</span>
        }
      </main>
      <button type='submit' className='sub'>Submit</button>
    </form>
  </div>
  )
}

export default ChangePassword