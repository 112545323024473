import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './imageUpload.css'

const Upload = ({userProfile, addNotis}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {paymentMethod, amount }= location.state
    console.log(paymentMethod, amount)
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  // Handle file selection
  const handleImageChange = (e) => {
    const preview = e.target.files[0]  // Get the selected file
    setFile(e.target.files[0]); // Get the selected file
    if (preview) {
      setImage(URL.createObjectURL(preview));  // Create a preview URL
    }
  };
  const depositData = {
    payment_method : paymentMethod,
    amount: amount,
    proof: file
  }
const [alert, setAlert] = useState(false)
  function handleSubmit(){
    try{
      const token = localStorage.getItem('token')
        axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/deposit', depositData, {
          withCredentials: true,
            headers : {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(res=>{
            console.log('paid')
            setAlert(true)
            setTimeout(()=>{
              navigate('/deposit')
            }, 2000)
            const notis2 = {
              title: 'Deposit confirmation',
            message: `You've successfully deposited ${depositData.amount} into your account. After confirmation your account will be credited automatically`
          }
          addNotis(notis2)
        })
        .catch(e=>{
            console.log('not paid', e)
        })
    }catch(e){
        console.log(e)
    }
  }
  

  return (
    <div className='upload'>
      <h3>
        Upload
      </h3>
      <section className='mainCon'>
      {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      {/* Hide the default file input */}
      <input 
        type="file" 
        accept="image/*" 
        id="file-input" 
        onChange={handleImageChange} 
        style={{ display: 'none' }} // Hide the default input
      />
      
      {/* Custom label to act as button */}
      <label htmlFor="file-input" style={{margin: 'auto'}} className="custom-file-upload">
        Choose Image
      </label>

      {image && (
        <div>
          <h3>Image Preview:</h3>
          <img src={image} alt="Selected Preview" id='imgPrv' />
        </div>
      )}
    </div>
      <button onClick={handleSubmit}>Upload</button>
      {
        alert && <p>Proof submited and deposit is processing</p>
      }
      </section>
    </div>
  )
}

export default Upload
