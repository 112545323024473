import React from 'react'
import { useState } from 'react';
import './login.css'
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom';


const Register = () => {
    const [buttonClicked, setButtonCLicked] = useState(false)
    const [text, setText]= useState(false)
    const [resMessage, setResMessage] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [valCap, setValCap] = useState(null)
    const [eye1, setEye1]= useState(false)
    const [eye2, setEye2]= useState(false)
    const navigate  =  useNavigate()
    // Handle checkbox change event
    const handleCheck = (event) => {
      setIsChecked(event.target.checked);
      console.log("Checkbox is checked:", event.target.checked);
    };

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        cpassword: '',
      });
    
      function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      }
    
      function handleSubmit(event) {
        event.preventDefault();
        if(formData.password === formData.cpassword){
          console.log(formData);
          axios.post('https://dashboard-6h1t.onrender.com/api/v1/authentication/register', 
           {
                firstName: formData.firstname,
                lastName: formData.lastname,
                email: formData.email,
                password: formData.password,
            }
          )
          .then(res=>{
            console.log(res)
            navigate('/login')
            setButtonCLicked(false)
            setText(true)
            setResMessage('Resgistration successful')
          })
          .catch(e=>{
            console.log(e)
            setButtonCLicked(false)
            setText(true)
            setResMessage('Email already taken, please confirm your details')
          })
        }else if(formData.password !== formData.cpassword){
          setText(false)
        }
      }
  return (
    <div className='register'>
      <section className='left'>
        <img alt='real' src='/images/real.svg' />
        <h4>THE MATRIX IS CRACKED.</h4>
        <p>THIS IS YOUR CHANCE.</p>
        <h4>A PORTAL EXISTS - AN ESCAPE.</h4>
        <p>THIS WILL BE THE MOST IMPORTANT DECISION OF YOUR LIFE.</p>
        <p>TAKE THE RED PILL.</p>
        <h4>JOIN THE REAL WORLD.</h4>
        <p style={{color:'blue'}}>WE ARE WAITING FOR YOU.</p>
      </section>
      <section className='right'>
        <main>
            <img alt='logg' src='/images/reall.svg' />
            <div>
                <h1>JOIN THE REAL WORLD</h1>
                <h4>ESCAPE THE MATRIX</h4>
            </div>
        </main>
        <form onSubmit={handleSubmit}>
        <div>
        <label htmlFor="firstname">First Name</label>
        <input
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="lastname">Last Name</label>
        <input
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <span className='inputConb'>
        <input
          name="password"
          type= {eye1 ? 'text' : 'password'} 
          value={formData.password}
          onChange={handleChange}
        />
         <img alt='eye' src='/images/eye.png' className='eye' style={{
              backgroundColor: 'black',
              width: '15px',
              padding: '7px',
              objectFit: 'contain'
            }} onClick={()=>{
            setEye1(!eye1)
        }} />
        </span>
      </div>
      <div>
        <label htmlFor="cpassword">Repeat password</label>
        <span className='inputConb'>
        <input
          name="cpassword"
          type= {eye2 ? 'text' : 'password'} 
          value={formData.cpasswordpassword}
          onChange={handleChange}
        />
         <img alt='eye' src='/images/eye.png' className='eye' style={{
              backgroundColor: 'black',
              width: '15px',
              padding: '7px',
              objectFit: 'contain'
            }} onClick={()=>{
            setEye2(!eye2)
        }} />
        </span>
      </div>
      <article className='agree'>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheck}
          style={{width: 'fit-content'}}
        />
        <label htmlFor="terms">I agree to Hustlers University <span style={{color:'red'}}>Terms & Conditions</span> and <span style={{color: 'red'}}>privacy policy</span></label>
      </article>
      {text &&  <span style={{
          fontSize: '12px',
          backgroundColor: '#fce1e0',
          color: '#9a4c4a',
          padding: '15px',
          textAlign: 'center'
        }}>
          {resMessage}
          </span>
        }
      <div style={{margin: 'auto'}}>
      <ReCAPTCHA
        sitekey='6LcWWz4qAAAAAMgpauX2NHUBCoFAEzRpb8-IVpRk'
        onChange={(val)=>setValCap(val)}
      />
      </div>
      <button disabled={!valCap} onClick={()=>{
        setButtonCLicked(true)
      }}>{buttonClicked ? 'Loading...': 'Sign up'}</button>
        </form>
        <small>Already have an account? <a href='/login'>Sign in</a></small>
      </section>
    </div>
  )
}

export default Register
