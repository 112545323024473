import React from 'react'
import { useState } from 'react'
import DepositHistory from '../components/DepositHistory'
import WithdrawHistory from '../components/WithdrawHistory'
import Footer from '../components/footer'

const Transactions = ({userProfile}) => {
  const [deposit, setDeposit] = useState(true)
  const [withdraw, setWithdrawal] = useState(false)

  function depositBoolean(){
    setDeposit(false)
    setWithdrawal(true)
  }

  function withBoolean(){
    setWithdrawal(false)
    setDeposit(true)
  }
  return (
    <div className='tx'>
      <h3>RECORDS</h3>
      <main className='txtab'>
        <p className= {deposit ? 'active': 'inactive'} onClick={withBoolean} >Show Deposit history</p>
        <p className={withdraw ? 'active': 'inactive'} onClick={depositBoolean}>Show Withdrawal history</p>
      </main>
      {
        deposit && <>
          <DepositHistory  />
        </>
      }
      {
        withdraw && <>
        <WithdrawHistory/>
        </>
      }
      <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  )
}

export default Transactions