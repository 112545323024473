import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LocationComponent = () => {
  const [location, setLocation] = useState({ country: '', flag: '', city: '' });
  const [error, setError] = useState('');

  // Replace with your OpenCage API key
  const OPEN_CAGE_API_KEY = 'ff92f6f5d06d4a979403e03b6b5062bc';

  useEffect(() => {
    // Get user's geolocation (latitude and longitude)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        
        // Call OpenCage API to reverse geocode the coordinates
        getCountryFromCoordinates(latitude, longitude);
      }, (error) => {
        console.error(error);
        setError('Location access denied.');
      });
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  const getCountryFromCoordinates = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lon}&key=${OPEN_CAGE_API_KEY}`
      );
      
      if (response.data && response.data.results && response.data.results.length > 0) {
        const components = response.data.results[0].components;
        const flagss = response.data.results[0].annotations.flag;
        setLocation({
          country: components.country,
          flag: flagss,
          city: components.city || components.town || components.village || '',
        });
      } else {
        setError('No location data found.');
      }
    } catch (error) {
      console.error("Error fetching location data from OpenCage:", error);
      setError('Unable to get location.');
    }
  };
  

  return (
    <div style={{
        color: 'white'
    }}>
     {location.flag} {location.country}
    </div>
  );
};

export default LocationComponent;
