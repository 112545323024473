import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartData, ScriptableContext } from 'chart.js';
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CryptoExchangeBarChart = () => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchInterval = 60000; 
    useEffect(() => {
        let isMounted = true;
        let intervalId;
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://api.coingecko.com/api/v3/exchanges');
                if (isMounted) {
                    const exchanges = response.data.slice(0, 15); 
                    const validExchanges = exchanges.filter(exchange => exchange.name && typeof exchange.trust_score === 'number');
                    const fluctuatingData = validExchanges.map(exchange => ({
                        name: exchange.name,
                        trustScore: Math.floor(Math.random() * 100),
                    }));

                    setChartData({
                        labels: fluctuatingData.map(data => data.name),
                        datasets: [{
                            label: 'IEO Returns (%)',
                            data: fluctuatingData.map(data => data.trustScore),
                            backgroundColor: 'rgba(0, 102, 204, 0.8)',
                            borderColor: 'white',
                            borderWidth: 1,
                        }] 
                    });
                    setError(null);
                }
            } catch (err) {
                if (isMounted) {
                    setError('Failed to fetch data, confirm network availability or try again later.');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData(); 
        intervalId = setInterval(fetchData, fetchInterval);

        return () => {
            isMounted = false;
            clearInterval(intervalId);
        };
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value) => `${value}%`,
                color: 'black',
            },
        },
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                },
                // title: {
                //     display: true,
                //     text: 'Ave. returns (%)',
                // },
            },
            y: {
                title: {
                    display: true,
                    text: 'Exchanges',
                },
            },
        },
    };

    return (
        <div style={{ position: 'relative', height: '60vh', width: '90%', margin: 'auto'}}>
            {loading && <p>Loading data...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!loading && !error && chartData && <Bar data={chartData} options={options} />}
        </div>
    );
};

export default CryptoExchangeBarChart;

