import React from 'react'
import { useState, useEffect } from 'react'
import './Comp.css'

const TopNav = ({notis, isNotis, notisData, userProfile, toggleMenu, openMenu, toggleMMenu, mopenMenu, isProfileActive, profileCond}) => {
  const [mobile, setMobile] = useState(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1100px)');
    const handleMediaChange = (event) => {
      setMobile(event.matches); // Set to true if matches, false otherwise
    };
    handleMediaChange(mediaQuery); // Set initial value
    mediaQuery.addEventListener('change', handleMediaChange); // Listen for changes
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange); // Clean up listener
    };
  }, []);
  // function showDropdown(isProfile){
  //   isProfileActive(!profileCond)
  // }

  return (
    <div className='topnav' style={{width: openMenu ? '98%': '88%'}}>
      {mobile ? <div onClick={()=>{
        toggleMMenu(mopenMenu ? false: true)
        console.log('togggle')
       }}><img alt='menu' src='/images/menu.png' /></div> : <div onClick={()=>{
        toggleMenu(openMenu ? false: true)
        console.log('togggle')
       }}><img alt='menu' src='/images/menu.png' /></div>} 
      <div className='profileCon'>
      <img alt='language' src='/images/uk.png' width={40} />
      <div className='notisCon'>
      <img alt='notis' src='/images/not.png' className='notisBtn' onClick={()=>{
        isNotis(!notis)
        isProfileActive(false)
      }} />
      {
        notisData.length === 0 ? <></> : <small>{notisData.length}</small>
      }
      </div>
      <span onClick={()=>{
        isProfileActive(!profileCond)
        isNotis(false)
      }}>
      <img alt='profile' src={userProfile.profile} className='pfp' />
      <p>{userProfile.firstName}</p>
      <img alt='arrowDown' src='/images/arrd.png' className='arrd'/>
      </span>
      </div>
    </div>
  ) 
}

export default TopNav