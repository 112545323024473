import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Pages.css'
import Footer from '../components/footer'
const Deposit = ({userProfile}) => {
  console.log(userProfile);
  const [deposit, setDeposit] = useState(false)
  const [usdt, setUsdt] = useState(false)
  const [btc, setBtc] = useState(true)
  const [amt, setAmt] = useState(0)
  const [text, setText] = useState('')
  function handleChange(e){
    setAmt(e.target.value)
  }
  function handleSubmit(e){
    e.preventDefault()
    if(amt > 0){
      setDeposit(true)
    }else{
      setText('amount must be greater than 0')
    }
  }
  const [copySuccess, setCopySuccess] = useState(false)

  return (
    <div className='deposit'>
{
  deposit && <>
    <div className='depositmenu'>
      <nav>
        <h6>Deposit crypto</h6>
        <img alt='' src='/images/cancel.png' onClick={()=>{
          setDeposit(false)
        }} />
      </nav>
      <main>
        <p>${amt} Wallet Address</p>
        <div className='addd'><p>{usdt ? '0x39c2d0ba8bfda0141f65f28f1571435e91dcaafa' : '17kT7VtRMTQB9apz8s9gMnFxqRiEHUrmJ8'}</p><h4 onClick={()=>{
          navigator.clipboard.writeText(usdt ? '0x39c2d0ba8bfda0141f65f28f1571435e91dcaafa' : '17kT7VtRMTQB9apz8s9gMnFxqRiEHUrmJ8')
          setCopySuccess(true)
        }}>{copySuccess ? 'Copied': 'Copy'}</h4></div>
      </main>
      <small style={{color: '#f34758'}}>Only send {usdt ? 'Tether US (USDT)': 'Bitcoin (BTC)' } tokens to this address</small>
      <div className='addd'><p>{amt}</p></div>
      <small>{userProfile.firstName} 👋 Once you have made the payment, please be so kind as to upload your proof of payment. Your cooperation in providing the proof of payment is greatly appreciated. Thank you!</small>
      <Link to='/upload' state={{   paymentMethod : usdt ? 'usdt' : 'btc',
          amount: amt  }} style={{color: '#f34758'}}>Submit Proof of payment</Link>
    </div>
  </>
}
      <h3>DEPOSIT</h3>
      <div className='mainCon' style={{opacity: deposit ? '0.4' : '1'}}>
        <header>
          <h2>Deposit crypto</h2>
          <p>Deposit Crypto in your Account. Your money will be held by a third-party escrow system until your investment expires.</p>
        </header>
        <article>
          <main className='currency'>
              <div style={{boxShadow: btc ? '0px 7px 10px #3f82be': ''}}  onClick={()=>{
                  setBtc(true)
                  setUsdt(false)
                }}>
                <img alt='btc' src='/images/btc.png'/>
                <small>BTC</small>
              </div>
              <div style={{boxShadow: usdt ? '0px 7px 10px #3f82be': ''}} onClick={()=>{
                  setBtc(false)
                  setUsdt(true)
                }}>
                <img alt='usdt' src='/images/usdt.png' />
                <small>USDT</small>
              </div>
          </main>
          <main className='addMinus'> 
          <span className='plus'onClick={()=>{
            setAmt(amt + 100)
          }}>+ 100</span>
          <span className='minus' onClick={()=>{
            if(amt > 0){
              setAmt(amt - 100)
            }
          }}>- 100</span>
          </main>
          <form onSubmit={handleSubmit}>
            <label htmlFor='amt'>Enter Amount</label>
            <input
              name='amt'
              placeholder='$0'
              value={amt}
              onChange={handleChange}
            />
            {text}
            <button type='submit'>Continue with payment</button>
          </form>
          <p className='bc'>Buy crypto <img alt='checked' src='/images/checked.png'/></p>
        </article>
      </div>
      <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  )
}

export default Deposit