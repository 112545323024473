import React from 'react'
import { useState } from 'react'
import Balance from '../components/Balance'
import Footer from '../components/footer'

const Referral = ({userProfile}) => {
  const [referral, setreferral] = useState(true)
  function isrefHis(){
    setreferral(!referral)
  }
  const [copySuccess, setCopySuccess] = useState(false)
  const refLink = localStorage.getItem('refLink')

  return (
    <div className='referral'>
      <h3>REFERRAL</h3>
      <main className='refBal'>
        <Balance name='Total Referral' amt='0' sign={false} />
        <Balance name='Referral Balance' amt='0.00' sign={true} />
        <Balance name='Active Referral' amt='0' sign={false} />
      </main>
      <main className='refLink'>
        <h3>Your Referral Link</h3>
        <p className='intro'>Greetings, Micheal! Below is your referral link, and with it, you have the opportunity to earn for a lifetime, as long as your downline continues to invest</p>
        <div className='linkkk'>
          <span id='link'><small>{refLink}</small></span>
          <span id='copy' onClick={()=>{
          navigator.clipboard.writeText(refLink)
          setCopySuccess(true)
        }}>{copySuccess ? 'Copied': 'Copy link'}</span>
        </div>
        <span className='socials'>
          <a href={`https://wa.me/?text=${refLink}`}>Whatsapp</a>
          <a href={`https://twitter.com/intent/tweet?text=${refLink}`}>Twitter</a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${refLink}`}>Facebook</a>
        </span>
        <p className='refHis' onClick={isrefHis} >See History</p>
        {
          referral && <>
            <div className='depHis'>
              <h2>Referral History</h2>
              <main>
            <ul className='header'>
                <li>Name</li>
                <li>Reward</li>
                <li>Date</li>
                <li>Status</li>
            </ul>
            <ul className='others'>
                <li>Yozie</li>
                <li>$0.00</li>
                <li>4 days ago</li>
                <li>Active </li>
            </ul>
        </main>
            </div>
          </>
        }
      </main>

      <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  )
}

export default Referral