import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

const Profile = ({ openUpdate, openPass, userProfile }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    // Assuming userProfile.Profile is a binary string, use btoa to convert to base64
    if (userProfile.Profile) {
      const base64Image = btoa(userProfile.Profile);
      setImageSrc(`data:image/jpeg;base64,${base64Image}`);
    }
  }, [userProfile.Profile]);

  return (
    <div className='userProfile'>
      <h3>Profile</h3>
      <article>
        <>
        {
          userProfile.mobile ? <section> <main>
            <h3>Welcome {userProfile.firstName}</h3>
            <p>Start Tier Two Verification <span><Link to='/kyc-application'>Click here</Link></span></p>
          </main>
          <main>
            <img alt='user' src={userProfile.profile} /> {/* Image is loaded here */}
            <h3>{userProfile.firstName} {userProfile.lastName}</h3>
            <p>ID: {userProfile.uniqueId}</p>
          </main> </section> : <main className='updateMessage'>
          <img alt='check' src='/images/check.png' />
          <h4>Welcome {userProfile.firstName}</h4>
          <p style={{color: 'white', width: '70%', lineHeight: '26px', fontSize: '18px'}}>You'll need to update your profile to continue</p>
          <button onClick={() => {
            openUpdate(true)
          }}>OK</button>
          </main>
        }
        </>
        <section>
          <h3>Personal information</h3>
          <main className='info'>
            <ul>
              <li>Full name:</li>
              <li>{userProfile.firstName} {userProfile.lastName}</li>
            </ul>
            <ul>
              <li>Mobile:</li>
              <li>{userProfile.mobile ? userProfile.mobile : 'Not set'}</li>
            </ul>
            <ul>
              <li>E-mail:</li>
              <li>{userProfile.email ? userProfile.email : 'Not set'}</li>
            </ul>
            <ul>
              <li>Location:</li>
              <li>{userProfile.country ? userProfile.country : 'Not set'}</li>
            </ul>
          </main>
          <main className='update'>
            <button onClick={() => openUpdate(true)}>Update profile</button>
            <button onClick={() => openPass(true)}>Change Password</button>
          </main>
        </section>
      </article>
      <div style={{ position: 'relative' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
