import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";


const UpdateProfile = ({addNotis, userProfile, openUpdate, updateCond}) => {
  const [submit, setSubmit] = useState('Submit')
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });

  }, []);
  const [text, setText]= useState('')
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  // Handle file selection
  const handleImageChange = (e) => {
    setFile(e.target.files[0]);
    const preview = e.target.files[0]  // Get the selected file
    if (preview) {
      setImage(URL.createObjectURL(preview));  // Create a preview URL
    }
  };

    const [formData, setFormData] = useState({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      gender: userProfile.gender,
      email: userProfile.email,
      mobile: userProfile.mobile,
      country: '',
      region: '',
      city: '',
      address1: userProfile.address1,
      address2: userProfile.address2,
      pcode: userProfile.pcode,
    });
  
    function handleChange(event) {
      const { name, value } = event.target;
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
 console.log(formData)
  
 function handleSubmit(event) {
  event.preventDefault();
  setSubmit('Loading')
  const token = localStorage.getItem('token');
  const { firstName, lastName, gender, email, mobile, country, region, city, address1, pcode } = formData;

  // Create a list of inputs with labels to validate each field one by one
  const formFields = [
    { name: 'First Name', value: firstName },
    { name: 'Last Name', value: lastName },
    { name: 'Gender', value: gender },
    { name: 'Email', value: email },
    { name: 'Mobile', value: mobile },
    { name: 'Country', value: country },
    { name: 'Region', value: region },
    { name: 'City', value: city },
    { name: 'Address 1', value: address1 },
    { name: 'Postal Code', value: pcode }
  ];

  // Loop through the form fields to check if any field is empty
  for (let i = 0; i < formFields.length; i++) {
    const field = formFields[i];

    // Check if the field value is empty
    if (!field.value) {
      setText(`Error: ${field.name} is required`);
      return; // Stop form submission if a required field is missing
    }
  }

  // If all fields are valid, proceed with form submission
  axios.put('https://dashboard-6h1t.onrender.com/api/v1/edit', { 
    ...formData, 
    profile: file ? file : null 
  }, {
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  })
  .then(res => {
    console.log(res);
    setText('Profile updated successfully');
    const notis2 = {
      title: 'Profile successfully updated',
      message: 'Your profile update was successful'
    };
    addNotis(notis2);
    setSubmit('Submit')
  })
  .catch(e => {
    console.log(e);
    setText('Error updating profile');
    setSubmit('Submit')
  });


    }
  return (
    <div className="updateProfile" style={{transform: updateCond ? 'translateX(0)': 'translateX(-26%)'}}>
    <header>
    <nav>
      <h3>Update Profile settings</h3>
      <img alt="cancel" src="/images/cancel.png"  onClick={()=>openUpdate(false)} />
    </nav>
    </header>
    <form onSubmit={handleSubmit}>
      <div className="pfpCon">
        {/* <img alt="pfp" src="/images/user.png" /> */}
        <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
    {/* Hide the default file input */}
    <input 
      type="file" 
      accept="image/*" 
      id="file-input" 
      onChange={handleImageChange} 
      style={{ display: 'none' }} // Hide the default input
    />
    
    {/* Custom label to act as button */}
    { !image &&  <label htmlFor="file-input" style={{margin: 'auto'}} className="file-upload">
    Upload your profile image
    </label>
    }

    {image && (
      <div>
        <img src={image} alt="Selected Preview" id='imgPrv' style={{width: '100px'}}/>
      </div>
    )}
  </div>
      </div>
      <main>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="lastName">Last Name</label>
        <input
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="gender">Gender</label>
        <select
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required={true}
        >
          <option>Select Gender</option>
          <option value='Male'>Male</option>
          <option value='Female'>Female</option>
        </select>
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="mobile">Phone number</label>
        <input
          name="mobile"
          type="number"
          value={formData.mobile}
          onChange={handleChange}
        />
      </div>
      {/* country city state */}
      <div>
  <label htmlFor="country">Country</label>
  <select
    onChange={(e) => {
      const selectedCountryIndex = e.target.value;
      const country = countriesList[selectedCountryIndex];
      console.log(country)
      if (country) {
        setCountryid(country.id); // Use country.id instead of index
        setFormData(prevFormData => ({
        ...prevFormData,
        country: country.name,
      }));
        GetState(country.id).then((result) => {
          setStateList(result); // Fetch and populate state list based on country
          setStateid(''); // Reset state selection
          setCityList([]); // Reset city list to an empty array
        });
      }
    }}
    value={countryid.name}
  >
    {countriesList.map((item, index) => (
      <option key={index} value={index}>
        {item.name}
      </option>
    ))}
  </select>
</div>

      <div>
  <label htmlFor="region">Region</label>
  <select
    onChange={(e) => {
      const selectedStateIndex = e.target.value;
      const state = stateList[selectedStateIndex];
      if (state) {
        setStateid(selectedStateIndex); // Update stateid with selected index
        setFormData(prevFormData => ({
        ...prevFormData,
        region: state.name,
      }));
        // Fetch cities based on the selected state and country
        GetCity(countryid, state.id).then((result) => {
          console.log("Fetched cities:", result); // Debug the city list
          if (result && result.length > 0) {
            setCityList(result); // Set the city list
            setCityid(''); // Reset city selection
          } else {
            console.log("No cities available for this state");
            setCityList([]); // Clear the city list
          }
        }).catch(error => {
          console.error("Error fetching cities:", error);
        });
      }
    }}
    value={stateid}
  >
    <option value="">Select a region</option>
    {stateList.map((item, index) => (
      <option key={index} value={index}>
        {item.name}
      </option>
    ))}
  </select>
</div>

<div>
  <label htmlFor="city">City</label>
  <select
    onChange={(e) => {
      const selectedCityIndex = e.target.value;
      const city = cityList[selectedCityIndex];
      if (city) {
        setCityid(selectedCityIndex); // Update cityid with selected index
        setFormData(prevFormData => ({
        ...prevFormData,
        city: city.name,
      }));
      }
    }}
    value={cityid}
  >
    <option value="">Select a city</option>
    {cityList.length > 0 ? (
      cityList.map((item, index) => (
        <option key={index} value={index}>
          {item.name}
        </option>
      ))
    ) : (
      <option value="">No cities available</option>
    )}
  </select>
</div>

      <div>
        <label htmlFor="address1">Address 1</label>
        <textarea
          name="address1"
          value={formData.address1}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="address2">Address 2</label>
        <textarea
          name="address2"
          value={formData.address2}
          onChange={handleChange}
        />
      </div>
      <div>
        <label htmlFor="pcode">Postal/Zip Code</label>
        <input
          name="pcode"
          value={formData.pcode}
          onChange={handleChange}
          type='number'
        />
      </div>
      { text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>{text}</span>
        }
      </main>
      <button type='submit' className='sub'>{submit}</button>
    </form>
  </div>
  )
}

export default UpdateProfile