import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'

const WithdrawHistory = () => {
  const [data, setData] = useState([])

  useEffect(()=>{
    const token = localStorage.getItem('token')
    axios.get('https://dashboard-6h1t.onrender.com/api/v1/actions/withdrawals', {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log(res)
      setData(res.data)
    })
    .catch(e=>{
      console.log(e)
    })
  },[])
  return (
    <div className='depHis'>
        <h2>Withdrawal History</h2>
        <main>
            <ul className='header'>
                <li>Reference id</li>
                <li>Amount</li>
                <li>Status</li>
                <li>Date In</li>
            </ul>
            {
              data && <>
                {data.map(item=>{
                  return <>
            <ul className='others'>
                <li>{item.clientId}</li>
                <li>${item.amount}</li>
                <li>{item.status}</li>
                <li>{item.withdrawnAt}</li>
                </ul>
                  </>
                })}
              </>
            }
        </main>
    </div>
  )
}

export default WithdrawHistory