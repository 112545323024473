import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ResetPwd from "./Pages/ResetPwd";
import Register from "./Pages/Register";
import ProtectedRoutes from "./utilis/ProtectedRoutes";
import Deposit from "./Pages/Deposit";
import Withdraw from "./Pages/Withdraw";
import Wallet from "./Pages/Wallet";
import Transactions from "./Pages/Transactions";
import Referral from "./Pages/Referral";
import Support from "./Pages/Support";
import Profile from "./Pages/Profile";
import SetupWallet from "./Pages/SetupWallet";
import Kyc from "./Pages/Kyc";
import ConnectWallet from "./Pages/ConnectWallet";
import { useState } from "react";
import UpdateProfile from "./components/UpdateProfile";
import ChangePassword from "./components/changePassword";
import Upload from "./Pages/Upload";
import UploadKyc from "./Pages/UploadKyc";
import Reset from "./Pages/Reset";
import { LiveChatWidget } from "@livechat/widget-react";


function App() {
  const [userProfile, setUserProfile] = useState({})
  const [profileDd, setProfileDd] = useState(false)
  const [updatePf, setUpdatePf] = useState(false)
  const [updatePass, setUpdatePass] = useState(false)
  function isProfileDd(isProfile){
    setProfileDd(isProfile)
  }
  function isUpdate(update){
    setUpdatePf(update)
  }
  function isPass(update){
    setUpdatePass(update)
  }
  useEffect(()=>{
    const token = localStorage.getItem("token")
    console.log(token)
    axios.get('https://dashboard-6h1t.onrender.com/api/v1/authentication/userprofile',{
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
        console.log(res.data[0])
        setUserProfile(res.data[0])
        localStorage.setItem("userData", res.data[0])
        localStorage.setItem('id', res.data[0].id) 
    })
    .catch(e=>{
        console.log(e)
    })
  },[])
  const [notis, setNotis] = useState(false)
  const [viewAll, SetViewAll] = useState(false)
  const [notisData, setNotisData] = useState([
    {
      title: 'Welcome to Hustlers university',
      message: 'Thank you for signing up with hustlers university'
    }
  ])
  function addNotis(notis2){
    setNotisData([
      ...notisData,
      notis2
    ])
  }
  function isNotis(upd){
    setNotis(upd)
  }

  return (
    <BrowserRouter>
    <LiveChatWidget license="18606822" />
     {
            updatePf && 
            (
            <UpdateProfile 
            addNotis={addNotis} 
            userProfile = {userProfile} 
            openUpdate={isUpdate} 
            updateCond={updatePf}
             />
             )
          }
          {
            updatePass && (
              <ChangePassword 
              addNotis={addNotis} 
              userProfile = {userProfile} 
              openUpdate={isPass} 
              updateCond={updatePass} 
              />
              )
          }
          {
            notis && <>
              <div className='notistab' style={{height: viewAll ? '80vh': 'initial'}}>
                <nav>
                  <p>Notifications</p>
                  <small onClick={()=>{
                    setNotisData([])
                  }}>Mark all as read</small>
                </nav>
                <article>
                {
                      notisData.map(item => { 
                        return <>
                  <div>
                    <img alt='img' src='/images/not.png' />
                    <span>
                        <h3>{item.title} </h3>
                        <p>{item.message}</p>
                    </span>
                  </div>
                  </>
                      })
                    }
                </article>
                <b onClick={()=>{
                  SetViewAll(!viewAll)
                }}>{viewAll ? 'Close' : 'view all'}</b>
              </div>
            </>
          }
     <Routes>
    <Route element={<ProtectedRoutes/>}>
    <Route path="/" element={<Home notis={notis} isNotis={isNotis} notisData={notisData} userProfile = {userProfile}/>} >
    <Route index element={<Dashboard  userProfile = {userProfile}/>} />
            <Route path="/deposit" element={<Deposit  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/withdraw" element={<Withdraw  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/wallet" element={<Wallet  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/transactions" element={<Transactions  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/referral" element={<Referral  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/support" element={<Support  addNotis={addNotis}  />} />
            <Route path="/profile" element={<Profile  userProfile = {userProfile}openUpdate={isUpdate} openPass={isPass} addNotis={addNotis}  />} />
            <Route path="/setup-wallet" element={<SetupWallet  userProfile = {userProfile}  addNotis={addNotis}  />} />
            <Route path="/kyc-application" element={<Kyc  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/connect-wallet" element={<ConnectWallet  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/upload" element={<Upload  userProfile = {userProfile} addNotis={addNotis}  />} />
            <Route path="/upload-kyc" element={<UploadKyc  userProfile = {userProfile} addNotis={addNotis}  />} />
    </Route>
    </Route>
     <Route path="/login" element={<Login />} />
     <Route path="/reset-password" element={<ResetPwd/>} />
     <Route path="/reset/:params" element={<Reset/>} />
     <Route path="/sign-up" element={<Register/>} />
     </Routes>
    </BrowserRouter>
  );
}

export default App;
