import React, { useState } from 'react'
import Footer from '../components/footer'
import { useNavigate } from 'react-router-dom'
import CryptoExchangeBarChart from '../components/cryptoExchangeBarChat'; //NEW CHANGE

const Dashboard = ({userProfile}) => {
  const [a, setA] = useState('$59,003')
  const [b, setB] = useState('$1.006')
  const [c, setC] = useState('$3,456')
  const [d, setD] = useState('$140.66')
  const [e, setE] = useState('$0.01633')
  const navigate = useNavigate()
  return (
    <div className='dashboard'>
    {
      userProfile && <>
     <section className='slides'>
      <span><img alt='crypto' src='/images/btc.png' />{a}</span>
      <span><img alt='crypto' src='/images/usdt.png' />{b}</span>
      <span><img alt='crypto' src='/images/eth.png' />{c}</span>
      <span><img alt='crypto' src='/images/sol.png' />{d}</span>
      <span><img alt='crypto' src='/images/tron.png' />{e}</span>
      <span><img alt='crypto' src='/images/btc.png' />{a}</span>
      <span><img alt='crypto' src='/images/usdt.png' />{b}</span>
      <span><img alt='crypto' src='/images/eth.png' />{c}</span>
      <span><img alt='crypto' src='/images/sol.png' />{d}</span>
      <span><img alt='crypto' src='/images/tron.png' />{e}</span>
      <span><img alt='crypto' src='/images/btc.png' />{a}</span>
      <span><img alt='crypto' src='/images/usdt.png' />{b}</span>
     </section>
     <section className='bio'>
      <h1>Hello {userProfile.firstName}</h1>
      <p>The art of accumulating wealth and generating income is something that can be cultivated through learning and practice.</p>
      <button onClick={()=>{
        navigate('/deposit')
      }}>Get Started</button>
     </section>
     <h4 style={{color: 'gray',  margin: 'auto'}}>Average IEO returns by exchange platforms</h4>
     <section className='ioe'>
     <CryptoExchangeBarChart />
     </section>
     <div>
      <Footer/>
     </div>
    </>
    }
    </div>
  )
}

export default Dashboard