import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import './imageUpload.css'



const UploadKyc = ({userProfile, addNotis}) => {
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  // Handle file selection
  const handleImageChange = (e) => {
    setFile(e.target.files[0]);
    const preview = e.target.files[0]  // Get the selected file
    if (preview) {
      setImage(URL.createObjectURL(preview));  // Create a preview URL
    }
  };

  const kycData = {
    proof: file
  }

  function handleSubmit(){
    try{
      console.log(file)
      const formData = new FormData()
      formData.append('document', file);
      const token = localStorage.getItem('token')
        axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/input-KYC-doc', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        })
        .then(res=>{
            console.log('paid')
            const notis2 = {
              title: 'KYC Verification',
            message: `KYC document sent successfully. To prevent scam, we request user validation`
          }
          addNotis(notis2)
        })
        .catch(e=>{
            console.log('not paid', e)
        })
    }catch(e){
        console.log(e)
    }
  }
  return (
    <div className='upload'>
    <h3>
      Upload
    </h3>
    <section className='mainCon'>
    {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
    {/* Hide the default file input */}
    <input 
      type="file" 
      accept="image/*" 
      id="file-input" 
      onChange={handleImageChange} 
      style={{ display: 'none' }} // Hide the default input
    />
    
    {/* Custom label to act as button */}
    <label htmlFor="file-input" style={{margin: 'auto'}} className="custom-file-upload">
      Choose Image
    </label>

    {image && (
      <div>
        <h3>Image Preview:</h3>
        <img src={image} alt="Selected Preview" id='imgPrv' />
      </div>
    )}
  </div>
    <button onClick={handleSubmit}>Upload</button>
    {
      alert && <p>Proof submited and deposit is processing</p>
    }
    </section>
  </div>
  )
}

export default UploadKyc
