import React from 'react'
import { useState } from 'react'
import './Pages.css'
import Footer from '../components/footer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Withdraw = ({userProfile, addNotis}) => {
  const [sub, setSub] = useState(false)
  const [withDrawSuccess, setWithDrawSuccess] = useState(false)
  const navigate = useNavigate()
  const [usdt, setUsdt] = useState(false)
  const [btc, setBtc] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const [kyc, setKyc] = useState(true)
  console.log(btc, usdt)
  const [formData, setFormData] = useState({
    currency: '',
    amount: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }
  function withdrawalRequest(e){
    e.preventDefault()
    setSub(true)
    const token = localStorage.getItem('token')
    axios.post('https://dashboard-6h1t.onrender.com/api/v1/actions/withdraw-funds', formData, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log(res)
      const notis2 = {
        title: 'Withdrawal confirmation',
      message: `You've successfully withdrawn ${formData.amount} from your account.`
    }
    addNotis(notis2)
    setWithDrawSuccess(true)
    })
    .catch(e=>{
      console.log(e)
    })
  }

  function handleWithdrawBtc(){
    setSub(true)
    const token = localStorage.getItem('token')
    axios.get('https://dashboard-6h1t.onrender.com/api/v1/actions/KYC', {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
    .then(res=>{
      console.log(res)
      setWithdraw(true)
    setSub(false)
    setKyc(true)
    })
    .catch(e=>{
      console.log(e)
      setWithdraw(false)
    setSub(false)
    setKyc(false)
      // navigate('/upload-kyc')
    })
  }
  return (
    <div className='withdraw'>
    {
      withdraw && <>
       <form className='wdDiv' onSubmit={withdrawalRequest}>
       {
        withDrawSuccess ? <div className='updateMessage'> <img alt='check' src='/images/check.png' /><h3>Withdrawal request sent successfully</h3> <button onClick={()=>{
          setWithDrawSuccess(false)
          setWithdraw(false)
        }}>Proceed</button></div> : <>
        <label htmlFor='currency'>Currency</label>
        <input
          name='currency'
          value={formData.currency}
          onChange={handleChange}
          placeholder='USDT or BTC'
        />
        <label htmlFor='amount'>Withdrawal amount</label>
        <input
          name='amount'
          value={formData.amount}
          onChange={handleChange}
        />
        <button type='submit'>
         {sub ? 'Loading....': 'Submit'}
        </button>
        </>
       }
      
       </form>
      </>
    }
    {
      !kyc && <div className='kyc-pop'>
        <h3>You have not completed your KYC verification. Click OK to verify now</h3>
        <button onClick={()=>{
           navigate('/upload-kyc')
        }}>OK</button>
      </div>
    }
      <h3>WITHDRAWAL</h3>
      <div className='mainCon' style={{opacity: withdraw ? '0.5': '1' || !kyc ? '1': '0.5'}}>
      <header>
          <h2>Initiate Crypto Withdrawal</h2>
        </header>
        <article>
          <p>
          Thank you for placing your trust in our investment platform. We take great pride in having you as a valued member of our community. Before proceeding with your fund withdrawal, we would like to request you to take one of the following actions:
          </p>
          <ul>
            <li>Update your <a href='/setup-wallet'>withdrawal Account Details.</a></li>
          </ul>
          <p>
          Once the above actions have been set up for your convenience, please proceed with your fund withdrawal by choosing your preferred means of withdrawal from the options provided below:
          </p>
          <main className='withdrawBtn'>
          <span style={{border: btc ? '1px solid white': ''}}  onClick={()=>{
              setBtc(true)
              setUsdt(false)
              handleWithdrawBtc()
            }} >
          <img alt='btc' src='/images/btc.png'/>
          </span>
          <span style={{border: usdt ? '1px solid white': ''}} onClick={()=>{
              setBtc(false)
              setUsdt(true)
              handleWithdrawBtc()
            }}>
          <img alt='usdt' src='/images/usdt.png' />
          </span>
          </main>
        </article>
      </div>
      <div style={{position: 'relative'}}>
      <Footer/>
      </div>
    </div>
  )
}

export default Withdraw