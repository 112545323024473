import React from 'react'
import './Comp.css'

const Balance = ({name, amt, sign}) => {
  return (
    <div className='balance'>
        <h4>{name}</h4>
        <h3>{sign ? '$' : ''}{parseFloat(amt).toFixed(2)}</h3>
    </div>
  )
}

export default Balance