import React, { useState, useEffect } from 'react'
import Footer from '../components/footer'
import Bankaccount from '../components/Bankaccount'
import axios from 'axios'

const SetupWallet = ({userProfile}) => {
  const [userUsdtAdd, setUserUsdtAdd] =  useState(false)
  const [userAdd, setUserAdd] =  useState(false)
  const [btcAdd, setBtcAdd] =  useState('')
  const [usdtAdd, setUsdtAdd] =  useState('')
  const [editBtc, setEditBtc] =  useState(false)
  const [editUsdt, setEditUsdt] =  useState(false)
  const [showBank, setShowBank] =  useState(null)
  useEffect(() => {
    if (userProfile) {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('id');
      console.log(userId);
      
      axios
        .get(`https://dashboard-6h1t.onrender.com/api/v1/actions/w_method/${userId}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          // setUserAdd(true)
          const adds1 = res.data[0];
          const adds2 = res.data[1];
          const allwallet = res.data
          allwallet.map(item=>{
            setUserAdd(item.type === 'BTC' ? true : false)
            setUserUsdtAdd(item.type === 'USDT' ? true : false)
          })
          setBtcAdd(adds1.type === 'BTC' ? adds1.address : adds2.address);
          setUsdtAdd(adds2.type === 'USDT' ? adds2.address : adds1.address);
        })
        .catch((err) => {
          console.error('Error fetching addresses:', err);
        });
    }
  }, []);
  function handleBtcChange(e){
    setBtcAdd(e.target.value)
  }
  function handleUsdtChange(e){
    setUsdtAdd(e.target.value)
  }

  function handleSubmitBtc(){
    const data = {
      type: 'BTC',
      address: btcAdd
    }
    console.log(data)
    const token = localStorage.getItem('token')
  userAdd ? axios.put(`https://dashboard-6h1t.onrender.com/api/v1/actions/w_method/${userProfile.id}`, data, {
    withCredentials: true,
      headers : {
          Authorization: `Bearer ${token}`
      }
  }) : axios.post(`https://dashboard-6h1t.onrender.com/api/v1/actions/w_method`, data, {
    withCredentials: true,
      headers : {
          Authorization: `Bearer ${token}`
      }
  })
  .then(res=>{
    console.log(res)
  })
  .catch(e=>{
    console.log(e)
  })
    console.log('submit')
    setEditBtc(false)
  }
  function closeBank(cond){
    console.log('food')
    setShowBank(false)
  }
  
  function handleSubmitUsdt(){
    const data = {
      type: 'USDT',
      address: usdtAdd
    }
    const token = localStorage.getItem('token')
    userUsdtAdd ? axios.put(`https://dashboard-6h1t.onrender.com/api/v1/actions/w_method/${userProfile.id}`, data, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    }) : axios.post(`https://dashboard-6h1t.onrender.com/api/v1/actions/w_method/`, data, {
      withCredentials: true,
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
  .then(res=>{
    console.log(res)
  })
  .catch(e=>{
    console.log(e)
  })
    console.log('submit')
    setEditUsdt(false)
  }
  return (
    <div className='setup'>
    {
      showBank && <div className='bnkacctCon'><Bankaccount showBank={showBank} closeBank={closeBank}/></div>
    }  
      <h3>UPDATE WITHDRAWAL WALLET</h3>
      <div className='mainCon'>
        <h4>Set Withdrawal information</h4>
        <p>We kindly request you to update your withdrawal information. However, please be aware that we cannot be held responsible for any losses that may occur due to the incorrect input of your wallet address. It is crucial to double-check and ensure the accuracy of your wallet address before proceeding with any withdrawals. Your cooperation in keeping your withdrawal information up-to-date and accurate is greatly appreciated. If you have any concerns or queries, please don't hesitate to reach out to our support team.</p>
        <main className='table'>
          <ul>
            <li></li>
            <li>Crypto</li>
            <li className='inputAdd'>Address</li>
            <li>Action</li>
          </ul>
          <ul>
            <li><img alt='btc' src='/images/btc.png'/></li>
            <li>BTC</li>
            {editBtc ? <>
              <li className='inputAdd'><input
                name='btcaddress'
                placeholder='Your Bitcoin address'
                value={btcAdd}
                onChange={handleBtcChange}
              /></li>
              <li className='checkclose'>
                <img alt='check' src='/images/check.png' onClick={handleSubmitBtc}/>
                <img alt='close' src='/images/close.png' onClick={()=>{
                  setEditBtc(false)
                }}/>
              </li>
            </>: <> <li className='inputAdd'>Address</li>
              <li className='editss' style={{color:'red'}} onClick={()=>{
                setEditBtc(true)
              }} ><img alt='edit' src='/images/edit.png'/>Edit</li></>}
          </ul>
          <ul>
            <li><img alt='usdt' src='/images/usdt.png' /></li>
            <li>USDT</li>
            {editUsdt ? <>
              <li className='inputAdd'><input
                name='usdtadd'
                placeholder='Your USDT address'
                value={usdtAdd}
                onChange={handleUsdtChange}
              /></li>
              <li className='checkclose'>
                <img alt='check' src='/images/check.png' onClick={handleSubmitUsdt}/>
                <img alt='close' src='/images/close.png' onClick={()=>{
                  setEditUsdt(false)
                }}/>
              </li>
            </>: <> <li className='inputAdd'>Address</li>
              <li className='editss' style={{color:'red'}} onClick={()=>{
                setEditUsdt(true)
              }} ><img alt='edit' src='/images/edit.png'/>Edit</li></>
              }
          </ul>
        </main>
        <main className='editBnk'>
          <p>If the current withdrawal method is not suitable for you, we encourage you to try other available means of withdrawal from the options provided below:</p>
          <ul>
            <li className='editss' onClick={()=>{
              setShowBank(true)
            }}><img alt='edit' src='/images/edit.png'/>Bank account</li>
            <li className='editss'><img alt='edit' src='/images/edit.png'/><a href='/connect-wallet'>Connect wallet phrase for instant withdrawal</a></li>
          </ul>
          <p>Please select the most convenient option from the list above for your withdrawal process. If you have any questions or need further assistance, our support team is ready to help.</p>
        </main>
      </div>
      <Footer/>
    </div>
  )
}

export default SetupWallet