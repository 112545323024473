import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';

const Kyc = ({ userProfile }) => {
  const [imgFile, setImgFile] = useState(null);
  const [text, setText]= useState(false)
  const [resMessage, setResMessage] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://dashboard-6h1t.onrender.com/api/v1/actions/KYC', {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: 'arraybuffer', // Ensure response is received as a buffer
      })
      .then((res) => {
        console.log(res.data[0].document)
        setImgFile(res.data[0].document)
        setText(true)
        setResMessage('KYC uploaded successfully')
      })
      .catch((e) => {
        console.log(e);
        setText(true)
        setResMessage('Failed to upload KYC')
      });
  }, []);

  return (
    <div className='kyc'>
      <h3>KYC APPLICATION</h3>
      <article>
        {imgFile ? (
          <img alt='kyc' src={imgFile} /> // Display the image when it is loaded
        ) : (
          <p> </p> // Loading placeholder while fetching the image
        )}
        <br/><br/>
        <main>
          <h3>KYC Verification</h3>
          <p>With tier verification you stand a chance to increase your daily withdrawal limit.</p>
          <p id='btt'>We encourage you to upload a clear and standard version of your preferred document</p>
          <Link to='/upload-kyc'>
            Click here for Verification
          </Link>
        </main>
      </article>
      {text &&  <span style={{
          fontSize: '12px',
          backgroundColor: '#fce1e0',
          color: '#9a4c4a',
          padding: '15px',
          textAlign: 'center'
        }}>
          {resMessage}
          </span>
        }
      <div style={{ position: 'relative' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Kyc;
