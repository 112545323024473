import React from 'react'
import { useState } from 'react';
import './login.css'
import axios from 'axios';

const ResetPwd = () => {
  const [sub, setSub] = useState(false)
  const [text, setText]= useState(false)
    const [formData, setFormData] = useState({
        email: '',
      });
    
      function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value
        }));
      }
    
      function handleSubmit(event) {
        setSub(true)
        event.preventDefault();
          console.log(formData);
          axios.post('https://dashboard-6h1t.onrender.com/api/v1/forgot-password', formData)
          .then(res=>{
            console.log(res)
            setText(true)
            setSub(false)
          })
          .catch(e=>{
            console.log(e)
            setText(false)
            setSub(false)
          })
      }
  return (
    <div className='login'>
    <section>
      <h2 style={{textAlign: 'center'}}>Enter your email and instructions will be sent to you</h2>
      <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email">Email</label>
        <input
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      {
        text &&   <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>
            Password reset mail successfully sent. Follow instructions in your mail to reset password
          </span>
      }
    
      <button>{sub ? 'Loading': 'Reset'}</button>
      </form>
    </section>
    </div>
  )
}

export default ResetPwd
