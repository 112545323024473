import React, { useState } from 'react'
import './Comp.css'
import { NavLink } from 'react-router-dom'


const SideBar = ({openMenu}) => {
  return (
    <div className='sidebar'>
     <div className='top'>
     <a href='/'><img alt='menu' src='/images/logo.png' /></a> 
     </div>
     <div className='btm'>
        <span><p>MENU</p></span>
          <section>
            <div>
              <NavLink exact to='/' activeClassName='active'>
                <img alt='menu' src='/images/db.png' />
              </NavLink>
                <p>Dashboard</p>
            </div>
            <div>
              <NavLink to='/deposit' activeClassName='active'>
                <img alt='menu' src='/images/dep2.png' />
              </NavLink>
                <p>Deposit</p>
            </div>
            <div>
              <NavLink to='/withdraw' activeClassName='active'>
                <img alt='menu' src='/images/wit2.png' />
              </NavLink>
                <p>Withdraw</p>
            </div>
            <div>
              <NavLink to='/wallet' activeClassName='active'>
                <img alt='menu' src='/images/wallet.png' />
              </NavLink>
                <p>Wallet</p>
            </div>
            <div>
              <NavLink to='/transactions' activeClassName='active'>
                <img alt='menu' src='/images/his.png' />
              </NavLink>
                <p>Transactions History</p>
            </div>
            <div>
              <NavLink to='/referral' activeClassName='active'>
                <img alt='menu' src='/images/ref.png' />
              </NavLink>
                <p>Referral Program</p>
            </div>
            <div>
              <NavLink to='/support' activeClassName='active'>
                <img alt='menu' src='/images/sup.png' />
              </NavLink>
                <p> 24/7 Support</p>
            </div>
            <div>
              <NavLink to='https://changelly.com/buy' activeClassName='active'>
                <img alt='menu' src='/images/pur.png' />
              </NavLink>
                <p>Purchase Crypto</p>
            </div>
          </section>
     </div>
    </div>
  )
}

export default SideBar