import React, { useState, useEffect } from 'react'
import ConnectEachWallet from '../components/connectEachWallet'
import axios from 'axios'

const ConnectWallet = ({userProfile}) => {
    const [connected1, setConnected1] = useState(false)
    const [connected2, setConnected2] = useState(false)
    const [connected3, setConnected3] = useState(false)
    const [connected4, setConnected4] = useState(false)
// write uhmm the get request for each address here 
  return (
    <div className='connect'>
      <h3>AUTO WITHDRAWAL</h3>
      <div className='mainCon'>
        <ConnectEachWallet name='Trust Wallet' imgsrc = '/images/TWT.png' connected = {connected1} setConnected={setConnected1} />
        <ConnectEachWallet name='Coinbase' imgsrc = '/images/cb.svg' connected = {connected2}setConnected={setConnected2} />
        <ConnectEachWallet name='Metamask' imgsrc = '/images/mm.png' connected = {connected3}setConnected={setConnected3} />
        <ConnectEachWallet name='Crypto.com' imgsrc = '/images/cc.png' connected = {connected4}setConnected={setConnected4} />
      </div>
    </div>
  )
}

export default ConnectWallet
