import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import Footer from '../components/footer';
import { useParams } from 'react-router-dom';


const Reset = ({openUpdate, updateCond}) => {
  const { params } = useParams();
  console.log(params)
  
  const [text, setText]= useState(false)
  const [eye1, setEye1]= useState(false)
  const [eye2, setEye2]= useState(false)
  const [formData, setFormData] = useState({
    newpassword: '',
    cnewpassword: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value  
    }));
  }
const formData2 = {
  password: formData.newpassword
}
  function handleSubmit(event) {
    event.preventDefault();
    if(formData.newpassword === formData.cnewpassword){
      console.log(formData);
      const token = localStorage.getItem('token')
      axios.put(`https://dashboard-6h1t.onrender.com/api/v1/reset/${params}`, formData2,  {
        withCredentials: true,
          headers : {
              Authorization: `Bearer ${token}`
          }
      })
      .then(res=>{
        console.log(res)
        setText(true)
      })
      .catch(e=>{
        console.log(e)
        setText(false)
      })
    }else if(formData.newpassword !== formData.cnewpassword){
      console.log('password does not match')
      setText(true)
    }
  }
  return (
    <div className='resetP'>
    <div className="resetForm" style={{
        width: '90%',
    }}>
    <form onSubmit={handleSubmit}>
      <div className="resetHeader">
      <h3>Enter New Password</h3>
        <img alt="pfp" src="/images/password.png"/>
      </div>
      <main>
    <img alt='logo' src='/images/logo.png' className='resetLogo'/>
      <div>
        <label htmlFor="newpassword">New password</label>
        <span className='inputCon'>
        <input
          placeholder="New Password"
          name="newpassword"
          value={formData.newpassword}
          onChange={handleChange}
          type= {eye1 ? 'text' : 'password'} 
        />
        <img alt='eye' src='/images/eye.png' className='eye' onClick={()=>{
            setEye1(!eye1)
        }} />
        </span>
      </div>
      <div>
        <label htmlFor="cnewpassword">Confirm Password</label>
        <span className='inputCon'>
        <input
          placeholder="Re-Type Password"
          name="cnewpassword"
          value={formData.cnewpassword}
          onChange={handleChange}
          type= {eye2 ? 'text' : 'password'} 
        />
        <img alt='eye' src='/images/eye.png' className='eye' onClick={()=>{
            setEye2(!eye2)
        }}  />
        </span>
      </div>
      {
        text &&   <span style={{
          fontSize: '12px',
          backgroundColor: '#fce1e0',
          color: '#9a4c4a',
          padding: '15px',
          textAlign: 'center'
        }}>
            Password reset successful 
          </span>
      }
      </main>
      <button type='submit' className='sub'>Submit</button>
    </form>
  </div>
  <Footer/>
  </div>
  )
}

export default Reset