import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = () => {
  const [userData, setUserData] = useState(null); 

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUserData(user ? true : false);
  }, []);
  if (userData === null) {
    return <div>Loading...</div>;
  }
  return userData ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;